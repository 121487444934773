import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import formatCategoryTitle from 'utils/format-category-title'
import getSeriesSpecsTables from 'utils/get-series-specs-tables'
import H2L from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import implementData from 'data/implement-data'
import YouTube from 'components/youtube'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql } from 'gatsby'
import SeriesTableGrid from 'components/category/series-table-grid'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const ImplementSeries = props => {
  const {
    all,
    breadcrumbs,
    category,
    heroImage,
    meta,
    series,
    seriesData,
    subcategory,
    tables,
    titles,
  } = getInitialProps(props)
  return (
    <Layout>
      <Helmet>
        <title>
          {titles.subcategory} - {titles.series} | Hutson Inc
        </title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            'numberOfItems': all.length,
            'itemListElement': all.map((implement, index) => ({
              '@type': 'ListItem',
              'position': index + 1,
              'url': `https://www.hutsoninc.com/implements/${category}/${subcategory}/${series}/${implement.sku}/`,
            })),
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title={titles.series}
        image={heroImage.childImageSharp.gatsbyImageData}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <SeriesTableGrid tables={tables} />

        {seriesData && seriesData.videoId && (
          <Section>
            <H2L>Videos</H2L>
            <YouTube videoId={seriesData.videoId} opts={{ list: seriesData.videoList }} />
          </Section>
        )}
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 350px;
`

const Section = styled.div`
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

const getInitialProps = ({
  data: { allDeereImplement, heroImage },
  pageContext: { category, subcategory, series },
}) => {
  const all = allDeereImplement.nodes

  const titles = {
    category: formatCategoryTitle(category),
    subcategory: formatCategoryTitle(subcategory),
    series: formatCategoryTitle(series),
  }

  const seriesData = implementData.find(obj => obj.series === series)

  const meta = {
    keywords: [titles.category, titles.subcategory, titles.series, 'John Deere'].toString(),
  }
  if (seriesData) {
    meta.description = seriesData.description
  } else {
    meta.description = `Check out ${titles.series} at Hutson.`
  }

  const tables = getSeriesSpecsTables(all, titles.series)

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Implements',
      link: `/implements/`,
    },
    {
      name: titles.category,
      link: `/implements/${category}/`,
    },
    {
      name: titles.subcategory,
      link: `/implements/${category}/${subcategory}/`,
    },
    {
      name: titles.series,
      link: `/implements/${category}/${subcategory}/${series}/`,
    },
  ]

  return {
    all,
    breadcrumbs,
    category,
    heroImage,
    meta,
    series,
    seriesData,
    subcategory,
    tables,
    titles,
  }
}

export const pageQuery = graphql`
  query implementSeries($series: String!, $subcategory: String!, $heroImagePath: String!) {
    heroImage: file(relativePath: { eq: $heroImagePath }) {
      ...FullWidthImage
    }
    allDeereImplement(
      filter: { series: { eq: $series }, subcategory: { eq: $subcategory } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        id
        sku
        name
        image
        overview
        category
        subcategory
        series
        seriesTableData {
          property
          info
        }
        seriesTableName
        slug
      }
    }
  }
`

export default ImplementSeries
