const overrides = [
  ['lawn-garden', 'Lawn & Garden'],
  ['residential-zero-turns', 'Residential Zero-Turn Mowers'],
  ['hay-forage', 'Hay & Forage'],
  ['walk-behind-mowers', 'Walk-Behind Mowers'],
  ['1-family', '1 Series'],
  ['2-family', '2 Series'],
  ['3-family', '3 Series'],
  ['4-family', '4 Series'],
  ['5-family', '5 Series'],
  ['6-family-utility', '6 Series Utility'],
  ['6-family-row', '6 Series Row Crop'],
  ['7-family', '7 Series'],
  ['8-family', '8 Series'],
  ['9-family', '9 Series'],
  ['9-series-balers', '9 Series Balers'],
  ['field-cultivators-mulch-finishers', 'Field Cultivators & Mulch Finishers'],
  ['3-point-mounted-sprayers', '3-Point Mounted Sprayers'],
  ['front-end-loaders-for-tractors', 'Front-End Loaders For Tractors'],
  ['livestock-equine-equipment', 'Livestock/Equine Equipment'],
  ['chain-unloading-manure-spreaders', 'Chain-Unloading Manure Spreaders'],
  ['end-wheel-grain-drills', 'End-Wheel Grain Drills'],
  ['3-point-offset-disks', '3-Point Offset Disks'],
  ['hrs-series', 'HRS Series'],
  ['hrn-series', 'HRN Series'],
  ['hrx-series', 'HRX Series'],
  ['hrc-series', 'HRC Series'],
  ['eu-series', 'EU Series'],
  ['eg-series', 'EG Series'],
  ['lawn-tractors', 'Riding Lawn Mowers'],
]

/**
 * Format a category title
 * @param {string} str - Title to format
 * @returns {string} Returns formatted title
 */
const formatCategoryTitle = str => {
  if (str) {
    const match = overrides.find(arr => arr[0] === str)
    if (match) {
      return match[1]
    }
    // If no override found, replace hypens with spaces and capitalize the first character of each word
    return str.replace(/-/g, ' ').replace(/\b\w/g, char => {
      return char.toUpperCase()
    })
  }
  return str
}

module.exports = formatCategoryTitle
